<template>
    <div class="page">
        <van-nav-bar :title="$t('concubine.concubine')" class="nav-bar">
             <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template> 
        </van-nav-bar>
        <!-- <div class="box">
            <p class="name">{{ this.xuanfeidata.xuanfei_name }}</p>
            <p class="title">{{ this.xuanfeidata.vo_title }}</p>
            <van-image width="98%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" />
            <van-button round="true" @click="yuyue" class="button" color="linear-gradient(to right, #7f5778 , #e5c2a0)">{{$t("concubine.reservation")}}</van-button>
        </div> -->
        <div class="feiziInfo">
            <div  class="topInfo">
                <div >
                    <span >{{ this.xuanfeidata.xuanfei_name }}</span>
                </div>
                <div >{{$t("concubine.hight")}}: {{ this.xuanfeidata.hight }} {{$t("concubine.bust")}}：{{ this.xuanfeidata.bust }}</div>
                <div >
                   <!-- <div >Students</div>
                    <div >Abalone</div>
                    <div >Hot dance</div> -->
                </div>
                <div >
                    <span  style="color: rgb(107, 34, 179); font-size: 14px;">{{$t("concubine.number")}}：</span>
                    <div  tabindex="0" role="radiogroup" class="van-rate van-rate--readonly">
                            <div v-for="(item, index) in number" :key="index">
                              <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="1" aria-checked="true" class="van-rate__item">
                                <i data-score="1" class="van-icon van-icon-star van-rate__icon van-rate__icon--full"
                                  style="color: rgb(242, 178, 71); font-size: 15px;"></i>
                              </div>
                            </div>
                    </div>
                </div>
                <div  style="color: rgb(107, 34, 179); font-size: 14px; margin: 10px 0px;">{{$t("concubine.dizhi")}}：{{ this.xuanfeidata.address }} </div>
                <div  style="color: rgb(153 153 153); font-size: 14px; margin: 10px 0px;margin-right: 20vw;">{{ this.xuanfeidata.xuanfei_info }} </div>
                <div  class="yuyueBox" @click="yuyue" >{{$t("concubine.reservation")}}</div>
                
                <div  class="dianzanimg">
                    <div  class="van-image" style="width: 60px; height: 60px;" @click="incrementCount">
                        <img src="/img/zan.c28032c8.png" class="van-image__img" style="object-fit: cover;">
                    </div>
                    <p  style="color: rgb(107, 34, 179); margin: 0px;" >{{ this.count }}</p>
                </div>
            </div>
            <!-- ##############-->
            <div  class="cenInfo">
                <div >
                   <!-- <div  class="van-image" style="width: 100%; height: 635px;">
                        <img src="http://apiss.tiktokj.shop/xuanfei/20240719/d18d2d248d877c3bbb27d99204f96bc1.jpg" class="van-image__img" style="object-fit: cover;">
                    </div> -->
                    <van-image width="98%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" />
                    
                    <div  style="padding-top: 20px; display: none;">
                        <video  id="my-video2" playsinline="false" webkit-playsinline="false" class="video-js"></video>
                    </div>
                </div>
            </div>
           <!-- ####################################### -->
           
            
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            xuanfeidata: [],
            count:0,
            number:0
        };
    },
    methods: {
        back() {
            /* this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
         */
        this.$router.push("Choose");
        },
       incrementCount() {
           this.count++
                },
        getxuanfeidata() {
            this.$http({
                method: 'get',
                url: 'xuanfeidata',
                data: { id: this.$route.query.id }
            }).then(res => {
                this.xuanfeidata = res.data;
                this.count = res.data.dianzan;
                this.number = res.data.number;
            });
        },
        yuyue() {
            this.$toast(this.$t("reservation.counselor"));
        }
    },
    created() {
        this.getxuanfeidata();
    }
};
</script>

<style>
.container {
    display: inline-block;
}
.box {
    width: 95%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 6.25rem;
}
.name {
    font-size: 1.125rem;
}
.title {
    font-size: 0.625rem;
}
.button {
    width: 10rem;
    height: 2.5rem;
    font-size: 0.9375rem;
    margin-top: 0.625rem;
}
.feiziInfo{
    background: #ebedf0;
}
.feiziInfo .topInfo{
    position: relative;
}
.feiziInfo>div{
    padding: 4vw 20px;
    background: #fff;
    margin-bottom: 4vw;
}
.feiziInfo .topInfo>div:first-child {
    font-weight: 600;
}
.feiziInfo .topInfo>div:nth-child(2) {
    color: #6b22b3;
    font-size: 5vw;
    margin: 10px 0;
}
.feiziInfo .topInfo>div:nth-child(3) {
    width: 50%;
}
.feiziInfo .topInfo>div:nth-child(3)>div{
    background: linear-gradient(50deg,#9b54ca,#e6557f);
    color: #fff;
    display: inline-block;
    border-radius: 10px;
    margin-right: 15px;
    font-size: 12px;
    padding: 8px;
    margin-bottom: 15px;
}
.van-rate {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}
.van-rate__item:not(:last-child) {
    padding-right: 4px;
}
.van-rate__item {
    position: relative;
}
.van-rate__icon {
    display: block;
    width: 1em;
    color: #c8c9cc;
    font-size: 20px;
}
.van-icon {
    position: relative;
    font: normal normal normal 14px/1 vant-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.van-icon, .van-icon:before {
    display: inline-block;
}
.feiziInfo .topInfo .dianzanimg{
    position: absolute;
    top: 4vw;
    right: 7vw;
    text-align: center;
}
.van-image__error, .van-image__img, .van-image__loading {
    display: block;
    width: 100%;
    height: 100%;
}
.van-tab__text-wrapper, .van-tabs {
    position: relative;
}
.van-tabs--line .van-tabs__wrap {
    height: 6vw;
}
.van-tabs__nav--line {
    box-sizing: content-box;
    height: 100%;
    padding-bottom: 15px;
}
.van-tab--active {
    color: #323233;
    font-weight: 500;
}
.van-tab {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 4px;
    color: #646566;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}
.van-tab__text--ellipsis {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.feiziInfo .cenInfo .imgswiper{
    width: 100%;
    overflow-x: scroll;
    margin: 4vw 0;
    display: flex;
    flex-wrap: nowrap;
}
.video-js{
    width: 100%;
    font-size: 3vw;
}
.video-js {
    font-size: 3vw;
    display: block;
    vertical-align: top;
    box-sizing: border-box;
    color: #fff;
    background-color: #000;
    position: relative;
    padding: 0;
    line-height: 1;
    font-weight: 400;
    font-style: normal;
    font-family: Arial,Helvetica,sans-serif;
    word-break: normal;
    height: 150px;
}
.feiziInfo>div{
    padding: 4vw 20px;
    background: #fff;
    margin-bottom: 4vw;
}
.feiziInfo .topInfo .yuyueBox{
    position: absolute;
    right: 8vw;
    bottom: 20px;
    color: #fff;
    padding: 10px 15px;
    background: linear-gradient(20deg,#e73266,#ee5380);
    border-radius: 7vw;
}
</style>
