<template >
  <van-tabbar
    v-if="show"
    v-model="active"
    active-color="#7e5678"
    :border="true"
    inactive-color="#979799"
  >
  <!-- 首页 -->
    <van-tabbar-item to="/Home">
      <span>{{ $t("foorter.index") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? home_base64Image_active : home_base64Image"
          :alt="$t('foorter.index')"
          v-show="show !== 0"
        />
        <img
          :src="props.active ? home_base64Image_active : home_base64Image"
          :alt="$t('foorter.index')"
          :class="$t('foorter.index')"
          style="height: 4rem"
          v-show="show === 0"
        />
      </template>
    </van-tabbar-item>
    <!-- 预约 -->
    <van-tabbar-item to="/Lottery?key=game1&id=10">
      <span>{{ $t("foorter.subscribe") }}</span>
      <template #icon="props">
        <img
           :src="props.active ? game_base64Image_active : game_base64Image"
          :alt="$t('foorter.subscribe')"
          v-show="show !== 1"
        />
        <img
          :src="props.active ? game_base64Image_active : game_base64Image"
          :alt="$t('foorter.subscribe')"
          :class="$t('foorter.subscribe')"
          style="height: 4rem"
          v-show="show === 1"
        />
      </template>
    </van-tabbar-item>
    <!-- 中间 -->
    <van-tabbar-item to="/Choose">
      <span></span>
      <span>{{ $t("concubine.concubine") }}</span> 
      <template #icon="props"> 
        <img 
          :src="props.active ? aixin_base64Image_active : aixin_base64Image"
        />
      </template>
    </van-tabbar-item>
    <!-- 视频 -->
    <van-tabbar-item to="/Video">
      <span>{{ $t("foorter.video") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? xiaolian_base64Image_active : xiaolian_base64Image"
          :alt="$t('foorter.video')"
          v-show="show !== 3"
        />
        <img
          :src="props.active ? xiaolian_base64Image_active : xiaolian_base64Image"
          :alt="$t('foorter.video')"
          :class="$t('foorter.video')"
          style="height: 4rem"
          v-show="show === 3"
        />
      </template>
    </van-tabbar-item>
    <!-- 我的 -->
    <van-tabbar-item to="/Mine">
      <span>{{ $t("foorter.my") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? user_base64Image_active : user_base64Image"
          :alt="$t('foorter.my')"
          v-show="show !== 4"
        />
        <img
         :src="props.active ? user_base64Image_active : user_base64Image"
          :alt="$t('foorter.my')"
          :class="$t('foorter.my')"
          style="height: 4rem"
          v-show="show === 4"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: 0,
      home_base64Image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOCAMAAAC5dNAvAAAAD1BMVEU4MT3IrtnIrtnIrtnIrtnXT1zBAAAABHRSTlMANK/nhwD8KwAAASlJREFUWMPtmMsWwyAIRDMD///NXURbTXwEsJtWuio5XhkiijmObf9roIgIsYymqrqId8JO4ILQtDAuokkKMSYYklQiJVAlwMuhoUC7BWehWeD1v0toEU2Eh1auKvXutI1C9qTtCAkexQCz4MkApBJBUKgngW9aaMZbWYXyYV5YTwRbVgFkZYrnc2famgXlqMe+HN9u0Rvl3hubmmx10+KVQ7NL/PtiKUxX4PRbOKaD1I0Tqc4UAIzgCAK3N+THNV/4xm3c7+IAktcqcuM4vAcYceVNgHFcda9AFJd7Fumdc0bcuUsDkMF8Nhxn4W/cxm3cM1yryZ3iBuOUuNrbS1XV2+Pk7ne5tX2crafJLRj1fWbjuI80Wu/Eogs4uJVRRI2/VR/itm1z2wvRERHwhIL5gAAAAABJRU5ErkJggg==',
      home_base64Image_active: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOCAMAAAC5dNAvAAAASFBMVEVHcEy9d66sgcKzfbnHcKDKbpyvf77YZozIcJ/RapO5ebO7eK+1e7bWZ42ugMDBdanFcqTTaZKpg8bQa5ayfbvIcJ/MbZrKbp1yUKMPAAAAC3RSTlMAP5NfcpnN8CHbDYRxECEAAAJ0SURBVFjD7ZjZcqwgEIbDIoooZhCd93/TgMom7QkudW4yP23TNs0XhsKZMl9fH/1dIcyUYrh5hkYMzIqhJ2hYeZHbsCaiKYXbm9vGVCJ2awOJynR9A1usAJH26vlQoK6dmP22RRuIHtm2wCPnt00uLdOawudOG1MbThqpqLngzIlBbIG42c5FSVm+gS3Z5mZSSVx2YlosC1XyyDVMFuv3DSRMarlZkN6cliGw8W8nBmtTprW9bOckdZyTPpb6Xx+4wdHsQh0/cojpCzo6MYTpTq+29kGddy7YLlsKbyDuzFhiQdo7F2yXXgIMnI8wz83W3gVg+Ftxdn9iEOtuKdnAlnTiDkyYFj1y1NLErdaJsIE2seUT5y1uSW2c9TghliHfOectVhfXdiEZcEwIxjyyi+jxvIScRJ2dzcIpobSpxA3RhpL0rGCTHZaxwTsnnxkAlM3R7BxXdsC0Qfjmbb1ZL5+ORwFcVJCWJ8mYEvIw7qrEszhwdd+DseDS0KfcrWuLA3DfRkPqQrhdoRuS+wPcVf0X3GRacEu3RVEANQA3TctQcEtnjVOCEKl4yOx5MA4W3b4YW3pcUo6LvrbpCdwb1FSVFBXjePKjTCa4CsDNkCaefI01fH5DZaW4OcPNd3Dvy7gRUrY6sAjEzSNgAC4vGktXN5asDsa9RsAAXF5UA7jX+AJsj1uSuyJwdS9QOW4FJqpv4XI9iwNX10Mtw+U1xkAcQMtwtqhPSo0Bq0N1D4mjJhLiNrcCnUxYA+8CfB1aK4OrEyUgf8OBV5+D5Rmg+3xHBX0FvvTw/po4/F7WVFeANT96K2spr8+KVw/9I+6jjy7rB/+6uboAE18wAAAAAElFTkSuQmCC', 
      aixin_base64Image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOBAMAAAB8hD0uAAAADFBMVEXHrdjIrtnIrtnIrtncvltRAAAABHRSTlMAkuX+NAjStgAAAcZJREFUeNrtVslhxCAMlHADHA3YqIGY6b+3PBbEJW+Sd5YXxoOOkUY20X9eHELdheCfUUlQiicilrazVgYAwBMLAKDYsOMFw0Wp7k4TBwAFAAIACABYMAeUHGIFXyE/GEzARUQBAEogogjcptvS8PW9WI65eWFBZeQAvJFt40saIQx8WaxoQqeGchrpKq0alRi42HGh4y6Dlr1MFi7+EncYZFl5dF46pWLw4vbLzrLHezARVqvKloiYnbrdZhjpErm1jdJDQ6+CELuf1/tudXtUf242mNu1JmWtmKB0g0768VVxmBSiD3eL+14qOyjMyXB6LpVNaFsWNadVdopjlVqCaqgLrHtL1fPRzZET+LV9WAB4ctLNdXdjp7DOl3NI3O/SSDq0+smtnTLo/gXMVte4aT5kAOL3ebLqgGW6NhjhuXF5mj5puJWeRjERjSk5azAZr1geDc6qyXj4WPDcu84exbuWYI3YVxmtYb9Htxwv5A7R+S2QPZW8e+mt/MPdtAGjmCysX9IoMFl1AkD6rBeMsp9JAMrLUyxzU28hDrp8KBERJRlw5X7+34iiuHK9+zFJinsLG3A3fez9Hcf1W8Fvfv8+a1jftSRluj4+t1MAAAAASUVORK5CYII=',
      aixin_base64Image_active: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOCAMAAAC5dNAvAAAAKlBMVEXCc6avf77GcqKuf7/UaZDAdaipg8XObJe8d66pg8awf724ebLJb53TaZFSbx+HAAAACXRSTlMAL1uCk6rW8/55eGODAAAB/klEQVR42u2YSZLDIAxFjWQxWbn/dbs8hsnkO/Gmqyw7G0CPL4SUdA/DY4/9YobYhhCsZdMZQm1x3GzzpnoIlZZ4znpmZ86H+GvawiuHAs6rXIOthwIaLwfI7Nfi2sZ3ikPlCYwzd8YaAt2L428u3c+4CD5Qam2MMUAvisNoEcosR9Cwe2dQnIBFBuLAHkC3ikPlwQ3ZxDh9fC+0Y54+8qy50t2nOPVfuvbd84HG174YKU4962eVmHn5pMeH0WjxpNR1sH5dlpYN+3Oa6bvKts6n6SKEZnwjfPZ+HvY+yxeto91z422JT3Nj/GZS5NfXludU9uFMyOGXX04jJczm9+3QkTcrbu+9BpyYFLXAJ2Edu5S1kwq0dDpZzIj36udPXTy0zbm6Eg7trprQzalR2iReqzhncc7r8lRnNOwz2qofw9JqILIImGVUs6Q7D+4VpOr1RIKx+6QDO9kc6urSPKB1SlUxnnG60ZrnM4juJgDPvJe3tyd3gZfQzg6b3ivch3wkW+tpK012dGOPNiY0B0XQEZhK6+bNZAulCSRxip5KxptDLhqWGYsFBNwmbWvMdSG0+fxemj9HUsZi4gXd9xHFCVaN5DK3Ny6XNsI/KcZXYgkuMbnyB7IR18M5d+kXz6LQneLkKiz1rnDuq/9VnOJe9+IedQ/u/+Aee+xtf6NbS9iOfUtAAAAAAElFTkSuQmCC',
      xiaolian_base64Image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOCAMAAAC5dNAvAAAADFBMVEVdUWXIrtnIrtnIrtmih8QOAAAAA3RSTlMAUMo7fLIkAAABSUlEQVRYw+2YwRKDIAxE3eX//7kXrYwukqTbjp2WI5JnElCyWZb/+MkBgGzrIAG8wnqS9sEiUbE2ohFWAB5hJF8Asgftueq3pTWkXRNpR/80RJst34GI067e/QQiSoNz2Twtm4OI0BLbP88JU8cJkwXMHU+aaBMDZGkbD+lnS9oD5GlXRkyHemVVcm5sVnNuZFd0bmSIonOre4hMxt2jKVZtWo9VBmbG1VMnXTHjWmutVWuZs/EbcKzi+IW4e2+FEXfvr+Lef5TB71OEj9BloZIHkoEpFZia5KlwHK3SN9lh9liJbhVnJE0cnJ5VXHRChZHrXrxFCykRvjqxHOXlQAvWIvI9J3GG8P2sy8VeNRGJgnX8DIDW2ZcFa7HUxmeEgFummEWUW+K5BahbHrvFu7u1EG18JI67ty3jbhrZW1r2hpu9Hfgf3zwek0AMlCsKVucAAAAASUVORK5CYII=',
      xiaolian_base64Image_active: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOCAMAAAC5dNAvAAAAS1BMVEVHcEyqgsWsgcKwfrzEc6XDc6XJb57CdKfPbJfMbZqpg8W7eLC2e7WnhMjWaI6/daqyfbuuf7+rgsPPa5bSaZPCc6bMbZrFcaLIcJ8Yb8uJAAAAC3RSTlMAfc62YhXqPciH7ejhyXoAAAL0SURBVFjD7VjpuqMgDL0qS1K1qK3L+z/psCjIZrXOn/nmnqCBEA6bpcDPzy/+R1BOiqp8tBqPsioIp1+T8aJamRweVcG/IktwWcbrZGV7gPIaIT8k04Tnu0yL9gQK+readqWB5NEOWj7iQU7MwUNSab7BqFgMFN/HGSm2IlbZ0O6yDGs7FJ/YtrqHLNq9Lo7HbbiIo/Hjl9kkX3Z+aTkM/Sb9kBU/Wea+v6I3GNbHRoZ+jyFIZIaP94cIeV083d2y/xJluqudFBNcLDBas0tJKVLzoHNMcLG0sdul5SsxG6S7gt5PxR9f1d1AFX/B0tqcCwlTOLlF0zTdSTHBk2AyaNVcQRcaKn8yeHMTfm/JXToSDF19Sxp/8Kq6vlQ+TNb+p1LWN+H/btldOubRrUZx8omTtU8naiFEfUJEylWmPTqxuYn1nda1sFx+Vi1COg85zgTWrEO66wjoRi1Km+D0Xu2zxc5j9OnYOErbaN8WYrUIl/a9hHn8DwXGAIJwwsYUBCMcIyP4PzJpmsftJYFqmdFJY1ntSqnFw/HN5vF/ZDjP8zhbjIwbq7W5COoFjc07yEz0F6g5gF5wKJsjMJr3d8unLLjYvGUegfqN2DJNsymElfjLJ4VlmZcNMrZWx8GZFMAUw52zsQf/tLgEWOujXgbStS+BczB02mNaVFjVZCvkyHRpxnDtkWry5mUUi3Y9MC2TCla5DsiDHqI73FGwXps/xJuKaQoJIbnT4rpi47L5x5sKXWcAhjEbstgPEhtQEnm9pGNQLwFlDZHcbsMrwKQCbBMghxBBm0JAejMrPX0xYWKAiABsMlVEbpm9O77SeHsqAuYOApDjyjHprmYPopy9I7zsK4knOziGkny5DF6Hh1C8SofHR0bJ91QhFBUSD3460OL7+YzZlOlp1Hun8cThnSXYksLIqasFeJ4CnLz7oBiXXfu6A56/jfrcQLh2E4WHhEB+LoISyJN9devGU010C9ZX14FyZbJMgHeuA3/xL+MPFL3RYvaTpScAAAAASUVORK5CYII=',
      game_base64Image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOCAMAAAC5dNAvAAAAD1BMVEUxKjXIrtnIrtnIrtnIrtnStJ9CAAAABHRSTlMAI3vM14GwhQAAAgFJREFUWMPtV0eCwzAI9ED+/+ZNbDUQLdl2MUcZD70dx0033fTXBCJ+pMREKIE9ypQDVhRbVfwx1RpgoCCmapxQBY+HTwrUDWHXb1UHS884/kP4NZCPwFR6J0XJNxfvo3U8eF+44LdXHGQukGcrzXR+kRWV8wtJJRxbu3KzNnZAEmLhWLuKoSjtJdzhWLtwUVhGCo5suCkFHcdOewuOzXK9FOHWLJ51ZKWiDIX0ufG65t+VB102ndQq9qTBAjcSQnuRjDyj2HqPG4vpEfl5lc0Te4EzY8ESDlYeBHBsNU58op0Vi+VNKC+S3gyFGYtFY9ojiyBRzFgscM2OV7HCzDv9xDEPjeFjVtkGt4dWtC2oUUtHAU7IlO6UeHQkcHtoMcKup/c+57dQHFvLg05FnI6z27HdjOD0zmUiFAcu58F5e5z9Ihz7w7yyXWhX+XBqz/kWHMAqpZkNyA3OHpbg2oKosvaCswequRKbYhO4YK81W7mGM7c9udb2NxS0s7Y91issPzzeEK61LJQ2utx38LY9azTk2vll5zXfMI05huO8Kigxye3lmwDnAf5pFI4yvZL6G7Qhat9n1XgGB+dH+10xq9G+HmOUHCw09sl5mpHTPEYl+SfGYJ5Vp5n3ZoSk/SZNi8tnb4lZXNrZfK0wP2PA3cmFa/biZfp4ZN10003/Tl/5vBaPHDVJIAAAAABJRU5ErkJggg==',
      game_base64Image_active: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOCAMAAAC5dNAvAAAAMFBMVEW6eLC/daq6eLC7eK+pgsXUaZG4ebLObJe2e7apg8Wvf765ebLDc6TJb53Qa5XVZ45SAd2TAAAACXRSTlMAG0B0jKzH3P4WzqKRAAACMklEQVR42u2Y25KDIAyGJQlBFLfv/7Y7ggcOQWy1O3vhT6dbTPhMiKxi1z169OivpZDYNsWE6gQMyJ4WQYv2BmwWHuf5Js1aOshYsX1brO7KtBEf2o9UmT9lP5S6L1Wf7q3ByeHR5zgpPLZDJEYAAEoPepE3xEcsC4srHsiwrd+C50PB5JAtFxvmI9bTcBs3lNcKybSSJ+HKydsHrctGLX+hjeNyuW62MBFIzMv6ScPjohR7BHtKQ7+0cCbqh2HoQ0lgMREiInE/K/xex/R5LbBfRYEQxCHy0IGoE35vg/JaUGrhtQdR7wCX12IbnwBO4zivxEVcWgvox1U+WVw6vfKE0GGkuRSzekYipG1QVgvYDOMy+6EDmXG2+wtlzJTiMLKocBPy4YQyJQMp999z2gvrsvC6DgBUVwZXwVFaiXF0W8OsSpHJjW7BubRxOiS2GUhuvU7CZQfHpLSQWffHD+B8oJ5LEXDr1/yBBJeJEZRSCtAVGuVDh7iZyGzcecU4dJeF38Pp6zgd/z+Z3NUWX3jGTVdlYtx0K266QV/E/Uy1j0l04BjhfqZK036t7QJd80xwFeny0UjXfNs4IzwHKvMxTktPqbqNq5xRfCbHl5xJ+4zilgvamejTU1edvBgHL0la3n9o0Tm5vxjBwVS2lyA6q3R+Twcnh5fdTHXjfN1xLlo1MjAHO+kTzpmLOdyX587S6tGmHnzpHM+bqryiCESDcOIVhPZEo0+9sHj06NH/1C96CHbvaMwrjAAAAABJRU5ErkJggg==',
      user_base64Image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOCAMAAAC5dNAvAAAAD1BMVEWMepjIrtnIrtnIrtnIrtnfqXklAAAABHRSTlMAGnThPnwuIAAAAcZJREFUWMPtmNmugzAMRJmZ/P8334cACYYsNqmqK9WPrXoa78u2/eSfCEgppZRSkki8hB2sk4g3sHSTMPAJloEhWqVilvMDxmkisUvxCoM040uQIR7UMPvuHsH/uGebZ57Lv+hpRHmfx6596LQe1A0vOJ+HvrWzn7BIV7e2HKQS5cdhYFv6PDHETRtvLW716/gN3PZNHJfi0towXphkmMFhW5QW7gK1FjeqxnL2stx5VhX3vjpuXfsPoLsx9ppf7hS+MQBsdh8GpoCj17eGF+/Mg8bPAiNFZSL7w9DAUw+fNQ8Mj4snrzzlFa28zw590dHduJd6RbPJ4U79i2vB59cRcEddmdINbt+m5iOvGvgN7rpOTRHtGlYHivlivE/ZNUyqPUnZv+oCa5iyha5mB7JVNQMsykg3kmEWYrdo7/+IiTBSl3fQphfWc0FrV1nf9gub1WaCdddFNFtorC5mXgPHYNVp4RArOp9XVvElX81A8amLZjsunRCOAttJi6NzTZbGM83EwVmnVwBKERgefS71iWS5yKCcZshLte7uH/bupJv4DlxoHbKej1uY6jxzrMmg2sutWgc3aeSrZ+SNmRuIE/WTr8gfRw8Q4i+dANEAAAAASUVORK5CYII=',
      user_base64Image_active: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOCAMAAAC5dNAvAAAASFBMVEW3erS3erO6eK+9dqzCc6bXZ43Jb57XZoylhcmpgsWlhcrBdKenhMiugL/WZ47HcKCmhMi3erSrgcOxfru9d63Ib57ObJfUaJDYjnX4AAAAD3RSTlMAGwQ4ZaiW9fr7yPB5p1UIhlrmAAAB8UlEQVRYw+3Y2W7jIBQG4MBhM2C8JDjv/6ZDnFaqJthnyajSSPnJVVR9Ouykl8sn/0e0yjmGMLeEEEej9DsY5NiwXWveHGLMIAYhf0s/EjLISstx7iZmLdHCfJDA93SeT8L1Tmp71sechTifJrLmQ4/DvJ62rBn9hTCs5xmAM3KYtg6M2VBxRRMVva8DzjF6awjcauhDR9BW8uDpkcKNdO664o3DEfLh+guFwtEXSl5wbaFvWnNdrguSq6Hv2YUQ+g1J4+gHVMK1RD+g1IhzI53TBuc4dyPcFqTdDOMmU+mGJHHeUtphnONcjBqw8gzrFaDGc40xr8+5Tf+wOGz0nOJybfSm28EnCR6g5uF1WzKXi8Trc8mIXtv6y/vrk5zw7f7wXiLr6Vd/XzlZT9vvHTDGvnLWFOD+9lHFOJvq1E+yzhTyylM7Vad6mKnuJEWEZm0Vz1SbCCjW6qJodf+zdAqqhhGt76Tj7Qu2Ya1xUjd7UCCkTZLaPxDAbsJ063ObOK7DeTnnO9z2Rn6Bu983aeuO3V2a3thZsXa3vYUi53oLRXlxcd1tUYSeL/1NK/N8OTrqi2A6bNEnpyezQI8cyCzQO/yxAsV6Aum9LUB8PTWymb7H7l/bRinq1agfl7aCYuwT/Sm1r8qD0u/81/KTX8gfa97GjrvRkdEAAAAASUVORK5CYII='
    };
  },
  methods: {},
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "game") {
        this.active = 1;
        this.show = true;
      } else if (to.name == "choose") {
        this.active = 2;
        this.show = true;
      } else if (to.name == "video") {
        this.active = 3;
        this.show = true;
      } else if (to.name == "mine") {
        this.active = 4;
        this.show = true;
      }else if (to.name == "Lottery") {
      this.show = false;
      }else if (to.name == "Login") {
      this.show = false;
      }else if (to.name == "Register") {
      this.show = false;
      } else {
        this.show = true;
      }
    },
  },
  created() {
    if (this.$route.name == "home") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "game") {
      this.active = 1;
      this.show = true;
    } else if (this.$route.name == "choose") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "video") {
      this.active = 3;
      this.show = true;
    } else if (this.$route.name == "mine") {
      this.active = 4;
      this.show = true;
    }else if (this.$route.name == "Lottery" ) {
      this.show = false;
    }else if (this.$route.name == "Login") {
      this.show = false;
    }else if (this.$route.name == "Register") {
      this.show = false;
    } else {
      this.show = true;
    }
  },
};
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 50px;

.van-tabbar {
  z-index: 99999;
  height: @tabbar-height;
}
.van-tabbar-item__icon img {
  height: @tabbar-img;
}
.van-tabbar-item {
  font-size: 22px;
}
.tui {
  width: 4rem;
  height: 4rem!important;
  //margin-top: -7.333vw;
  background-color: white;
  //border-radius: 50%;
  border: 10px solid white;
  z-index: 10;
}
[class*="van-hairline"]::after {
  border: none !important;
}
</style>
